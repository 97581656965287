<template>
  <div class="content">
    <div class="banner">
      <a href="http://arth.cc"></a>
    </div>
    <div class="wire">
      <ul>
        <li>
          <div>{{ $t('dapp.userYield.totalArt') }}</div>
          <div>21000000</div>
        </li>
        <li>
          <div>{{ $t('dapp.userYield.totalPower') }}</div>
          <div>{{ totalPower }}T</div>
        </li>
        <li>
          <div>{{ $t('dapp.userYield.totalRevenue') }}</div>
          <div>{{ Number(myAllProfit).toFixed(4) }}</div>
        </li>
        <li>
          <div>{{ $t('dapp.userYield.totalStrength') }}</div>
          <div>
            {{
              (
                Number(power) +
                Number(invitedPower) +
                Number(teamPower)
              ).toFixed(2)
            }}T
          </div>
        </li>
      </ul>
      <div class="transverseLine"></div>
      <div class="longString"></div>
    </div>
    <div class="personage">
      <div class="top">
        <ul>
          <li>
            <div>{{ $t('dapp.userYield.personalPower') }}</div>
            <div>{{ power }}T</div>
          </li>
          <li>
            <div>{{ $t('dapp.userYield.kineticEnergy') }}</div>
            <div>{{ invitedPower }}T</div>
          </li>
          <li>
            <div>{{ $t('dapp.userYield.starWarsforce') }}</div>
            <div>{{ teamPower }}T</div>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <ul>
          <li>
            <div>
              <span>{{ invitedNum }}</span>
              <span>{{ $t('dapp.userYield.invitedNum') }}</span>
            </div>
            <div>
              <span>{{ teamNum ?? 0 }}</span>
              <span>{{ $t('dapp.userYield.teamNum') }}</span>
            </div>
          </li>
          <!-- <li>
            <div>
              <span>{{ currentCirculation }} ART/Day</span>
              <span>{{ $t('dapp.userYield.currentCirculation') }}</span>
            </div>
            <div>
              <span>{{ nextCurrentCirculation }} ART/Day</span>
              <span>{{ $t('dapp.userYield.nextCurrentCirculation') }}</span>
            </div>
          </li> -->
          <li>
            <div>
              <span>{{ timer }}</span>
              <span
                >{{ Number(round) + 1
                }}{{ $t('dapp.userYield.roundCountdown') }}</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatAmount } from '@/utils/format.js'
  import { timer } from '@/utils/timer.js'
  import {
    onMounted,
    reactive,
    toRefs,
    ref,
    computed,
    watch,
    onBeforeUnmount,
  } from 'vue'
  import Web3 from 'web3'
  import detectEthereumProvider from '@metamask/detect-provider'
  import pool from '@/web3/pool.json'
  import address from '@/web3/address.json'
  import token from '@/web3/token.json'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { Toast } from 'vant'
  export default {
    name: 'UserYield',
    setup(props) {
      //#region 定义的数据和刷新
      const route = useRoute()
      const store = useStore()
      const refresh = computed(() => store.state.refreshData)
      watch(
        () => refresh,
        (value) => {
          if (value) {
            initData()
          }
        },
        { deep: true }
      )
      let useData = reactive({
        totalPower: 0, //总战力
        power: 0, //个人战力
        teamPower: 0, //星球战力
        invitedNum: 0, //邀请数量
        invitedPower: 0, //动能算力
        myAllProfit: 0, //总收益
        currentCirculation: 0, //当前释放
        timer: 0, //倒计时
        round: 0, //当前轮数
        timestamp: 0, //时间戳
        teamNum: 0,
        nextCurrentCirculation: 0, //下轮释放
      })
      let poolAbi = reactive({})
      let gtcAbi = reactive({})
      let kntAbi = reactive({})
      let userAddress = window.ethereum.selectedAddress
      if (!window.ethereum.selectedAddress) {
        setTimeout(() => {
          if (route.query.f) {
            userAddress = route.query.f
          }
          initContrac()
        }, 1000)
      } else {
        if (route.query.f) {
          userAddress = route.query.f
        }
        initContrac()
      }
      //#endregion
      //#region  初始化和获取数据
      async function initContrac() {
        const Provider = await reactive(detectEthereumProvider())
        const web3 = reactive(new Web3(Provider))
        poolAbi = new web3.eth.Contract(pool, address.pool)
        gtcAbi = new web3.eth.Contract(token, address.gctToken)
        kntAbi = new web3.eth.Contract(token, address.kntToken)
        timerStamp()
        initData()
      }
      async function initData() {
        //获取精度
        const precision = await kntAbi.methods.decimals().call()
        // 获取用户信息
        let getUserInfo = await poolAbi.methods
          .getUserInfo()
          .call({ from: window.ethereum.selectedAddress })
        //团队邀请
        useData.teamNum = getUserInfo.teamNum
        //邀请数量
        useData.invitedNum = getUserInfo.invitedNum
        //个人战力
        useData.power = Number(
          formatAmount(Number(getUserInfo.power), Number(precision))
        )
        //星球战力
        useData.teamPower = Number(
          formatAmount(Number(getUserInfo.teamPower), Number(precision))
        )
        //动能算力
        useData.invitedPower = Number(
          formatAmount(Number(getUserInfo.invitedPower), Number(precision))
        )
        // // 获取收益
        let getUserProfit = await poolAbi.methods
          .getUserProfit()
          .call({ from: window.ethereum.selectedAddress })
        useData.myAllProfit = Number(
          formatAmount(Number(getUserProfit.myAllProfit), Number(precision))
        )

        //获取全网战力
        let getTotalPower = await poolAbi.methods.getTotalPower().call()
        useData.totalPower = Number(
          formatAmount(Number(getTotalPower), Number(precision))
        )
        // 本轮释放、本轮倒计时、下一轮释放
        let getPool = await poolAbi.methods
          .getPool()
          .call({ from: window.ethereum.selectedAddress })
        useData.round = Number(getPool.round)
        let getTotalRoun = await poolAbi.methods
          .getTotalRound()
          .call({ from: window.ethereum.selectedAddress })
        //判断轮数控制释放量
        if (Number(getPool.round) + 1 > getTotalRoun) {
          useData.round = 9
          useData.currentCirculation = 'end'
          window.clearInterval(ms)
          useData.timer = 'end'
        } else {
          useData.currentCirculation = (
            getPool.currentCirculation / 30
          ).toFixed(0)
        }

        if (Number(getPool.round) + 1 >= getTotalRoun) {
          useData.nextCurrentCirculation = 'end'
        } else {
          if (Number(getPool.round) + 1 == 3) {
            useData.nextCurrentCirculation = 4762
          } else {
            useData.nextCurrentCirculation = useData.currentCirculation
          }
        }
      }
      let ms = null
      async function timerStamp() {
        //获取精度
        const precision = await kntAbi.methods.decimals().call()
        let getPool = await poolAbi.methods
          .getPool()
          .call({ from: window.ethereum.selectedAddress })
        useData.timestamp = (getPool.end - getPool.blockNum) * 3
        useData.timer = timer(useData.timestamp)
        clearInterval(ms)
        ms = setInterval(() => {
          useData.timestamp--
          useData.timer = timer(useData.timestamp)
          if (useData.timestamp <= 0) {
            timerStamp()
          }
        }, 1000)
      }
      //#endregion
      onBeforeUnmount(() => {
        clearInterval(ms)
      })
      return { ...toRefs(useData) }
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    padding: 2.8205rem 0 0 0;
    width: 100%;

    .wire {
      position: relative;
      padding: 0.5128rem 0;
      width: 100%;
      height: 6.1538rem;
      background: rgba($color: #16254f, $alpha: 0.2);
      margin-bottom: 0.5128rem;
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 50%;
          height: 2.5641rem;
          padding: 0.1282rem 0.3846rem;
          :nth-child(1) {
            opacity: 0.5;
            color: #fff;
            font-size: 0.359rem;
          }
          :nth-child(2) {
            background-image: -webkit-gradient(
              linear,
              left center,
              right center,
              from(#ffd25e),
              to(#d28500)
            ); /* 背景色渐变 */
            background-clip: text; /* 规定背景的划分区域 */
            -webkit-text-fill-color: transparent; /* 防止字体颜色覆盖 */
            font-size: 0.5128rem;
            font-weight: 700;
          }
        }
      }
      .transverseLine {
        width: 9.2308rem;
        height: 0.0513rem;
        background: linear-gradient(to top, #2e3c64, #07080a);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .longString {
        width: 0.0513rem;
        height: 5.1282rem;
        background: linear-gradient(to left, #07080a, #2e3c64);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .personage {
      .top {
        width: 100%;
        margin-bottom: 0.5128rem;
        ul {
          width: 100%;
          display: flex;
          justify-content: space-between;
          li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 3.4872rem;
            width: 2.8718rem;
            padding: 0.4103rem 0;
            background: url('./images/bg.png') center no-repeat;
            background-size: 100%;
            border-radius: 0.2051rem;
            div {
              text-align: center;
            }
            :nth-child(1) {
              color: #fff;
              opacity: 0.5;
              font-size: 0.3077rem;
            }
            :nth-child(2) {
              color: #fff;
              font-size: 0.5128rem;
            }
          }
          li:nth-child(2) {
            background: url('./images/bg2@2x.png') center no-repeat;
            background-size: 100%;
          }
          li:nth-child(3) {
            background: url('./images/bg3@2x.png') center no-repeat;
            background-size: 100%;
          }
        }
      }
      .bottom {
        color: #fff;
        ul {
          width: 100%;
          li {
            display: flex;
            padding: 0.2051rem;
            width: 100%;
            height: 1.8462rem;
            background: linear-gradient(to top, #202945, #161e34);
            border: 0.0256rem solid #323d5b;
            border-radius: 0.2051rem;
            margin-bottom: 0.3077rem;
            div {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              :nth-child(1) {
                font-size: 0.4103rem;
              }
              :nth-child(2) {
                font-size: 0.359rem;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    .banner {
      width: 100%;
      height: 5.3846rem;
      margin: 0 auto;
      display: flex;
      background: url('../images/banner.png') bottom no-repeat;
      background-size: 100%;
      a {
        width: 100%;
      }
    }
  }
</style>

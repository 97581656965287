export const timer = (time) => {
  let t = parseInt(time / 60 / 60 / 24)
  t = t < 10 ? '0' + t : t
  let h = parseInt((time / 60 / 60) % 24)
  h = h < 10 ? '0' + h : h
  let m = parseInt((time / 60) % 60)
  m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
  s = s < 10 ? '0' + s : s
  return t + '天' + h + '时' + m + '分' + s + '秒'
}
export const bigTimer = (time) => {
  return new Date(parseInt(time) * 1000)
    .toLocaleString()
    .replace(/年|月/g, '-')
    .replace(/日/g, ' ')
}

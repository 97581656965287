<template>
  <div class="invitation">
    <div class="invitationTitle">
      <div class="title">{{ $t('dapp.invitation.myInvitation') }}</div>
      <button class="titleBtn" @click="copy">
        {{ $t('dapp.invitation.inviteFriends') }}
      </button>
    </div>
    <div class="inviterMessage">
      <ul>
        <li>
          <div>{{ $t('dapp.invitation.myInviter') }}</div>
          <div>
            {{ inviter }}
          </div>
        </li>
        <li>
          <div>{{ $t('dapp.invitation.contractadres') }}</div>
          <div class="copy">
            <div class="text">{{ contractAddress(poolAddress) }}</div>
            <span @click="copyContract"></span>
          </div>
        </li>
      </ul>
    </div>
    <div class="inviterList">
      <div class="listTitle">
        <span>{{ $t('dapp.invitation.address') }}</span>
        <span>{{ $t('dapp.invitation.militaryCapability') }}</span>
        <span>{{ $t('dapp.invitation.time') }}</span>
      </div>
      <ul>
        <li v-for="item in invitedMembers" :key="item.address">
          <span>{{ addressSlice(item.member) }}</span>
          <span>{{ item.power }}T</span>
          <span>{{ generateTime(item.time) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { useRoute } from 'vue-router'
  import { Toast } from 'vant'
  import Web3 from 'web3'
  import detectEthereumProvider from '@metamask/detect-provider'
  import pool from '@/web3/pool.json'
  import address from '@/web3/address.json'
  import token from '@/web3/token.json'
  import { ref, reactive, onMounted, toRefs, computed, watch } from 'vue'
  import { formatAmount } from '@/utils/format.js'
  import { bigTimer } from '@/utils/timer.js'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'Invitation',
    setup() {
      const { t } = useI18n()
      const route = useRoute()
      //#region 监视刷新
      const store = useStore()
      const refresh = computed(() => store.state.refreshData)

      watch(
        () => refresh,
        (value) => {
          if (value) {
            initData()
          }
        },
        { deep: true }
      )
      //#endregion
      //#region 定义的数据
      function addressSlice(address) {
        return address && address.slice(0, 4) + '...' + address.slice(-4)
      }
      function contractAddress(address) {
        return address && address.slice(0, 10) + '...' + address.slice(-10)
      }
      let inviter = computed(() => {
        if (
          store.state.inviterAddress &&
          store.state.inviterAddress !=
            '0x0000000000000000000000000000000000000000'
        ) {
          return (
            t('toast.reality') + contractAddress(store.state.inviterAddress)
          )
        } else {
          return (
            t('toast.default') +
            contractAddress('0x4c2c4516F1F2FEF26495eAfAcCd792357990C9f2')
          )
        }
      })

      let userData = reactive({
        invitedMembers: [], //邀请人列表
      })
      let poolAbi = reactive({})
      let gtcAbi = reactive({})
      let kntAbi = reactive({})
      let userAddress = window.ethereum.selectedAddress
      if (!window.ethereum.selectedAddress) {
        setTimeout(() => {
          if (route.query.f) {
            userAddress = route.query.f
          }
          initContrac()
        }, 1000)
      } else {
        if (route.query.f) {
          userAddress = route.query.f
        }
        initContrac()
      }
      //#endregion

      //#region 初始化和获取数据
      async function initContrac() {
        const Provider = await reactive(detectEthereumProvider())
        const web3 = reactive(new Web3(Provider))
        poolAbi = new web3.eth.Contract(pool, address.pool)
        gtcAbi = new web3.eth.Contract(token, address.gctToken)
        kntAbi = new web3.eth.Contract(token, address.kntToken)
        initData()
      }
      async function initData() {
        //获取精度
        let precision = await kntAbi.methods.decimals().call()
        let getInvitedMembers = await poolAbi.methods
          .getInvitedMembers()
          .call({ from: window.ethereum.selectedAddress })
        userData.invitedMembers = getInvitedMembers.map((item) => {
          let power = formatAmount(Number(item.power), Number(precision))
          return { ...item, power }
        })
        //获取我的邀请人
        let invitedMe = await poolAbi.methods
          .getInvitedMe()
          .call({ from: window.ethereum.selectedAddress })
        store.commit('INVITER', invitedMe)
      }
      //#endregion
      function generateTime(time) {
        return bigTimer(time)
      }
      //#region 邀请模块
      let copy = () => {
        let input = document.createElement('input')
        input.value =
          window.location.origin + '?' + 'f=' + window.ethereum.selectedAddress
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
        Toast.success(t('toast.copyInvitation'))
      }
      //合约地址
      let poolAddress = ref(address.pool)
      let copyContract = () => {
        let input = document.createElement('input')
        input.value = poolAddress.value
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
        Toast.success(t('toast.copyAddress'))
      }
      //#endregion
      return {
        copy,
        ...toRefs(userData),
        addressSlice,
        generateTime,
        inviter,
        copyContract,
        poolAddress,
        contractAddress,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .invitation {
    .invitationTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.0769rem;
      .title {
        font-size: 0.4615rem;
        background-image: -webkit-gradient(
          linear,
          left center,
          right center,
          from(#ffd25e),
          to(#d28500)
        ); /* 背景色渐变 */
        background-clip: text; /* 规定背景的划分区域 */
        -webkit-text-fill-color: transparent; /* 防止字体颜色覆盖 */
        font-weight: 700;
      }
      .titleBtn {
        height: 0.6154rem;
        padding: 0 0.5128rem;
        font-size: 0.359rem;
        color: #ffd25e;
        border: 0.0256rem solid #ffd25e;
        border-radius: 0.3077rem;
      }
    }
    .inviterMessage {
      display: flex;
      margin-top: 0.2051rem;
      margin-bottom: 0.3077rem;
      width: 100%;
      height: 3.8974rem;
      border: 0.0256rem solid #505050;
      background-color: #2b3341;
      border-radius: 0.2051rem;
      ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.2051rem.2564rem;
        li {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 0.359rem;
          color: #fff;
          :nth-child(1) {
            opacity: 0.5;
            font-size: 0.4103rem;
          }
          .copy {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              width: 0.5128rem;
              height: 0.5128rem;
              background: url('./images/copy@3x.png') center no-repeat;
              background-size: 100%;
            }
            .text {
              font-size: 0.359rem;
              opacity: 1;
            }
          }
        }
      }
    }
    .inviterList {
      width: 100%;
      padding: 0.4103rem 0 0;
      background: rgba($color: #ffffff, $alpha: 0.1);
      border-radius: 0.3077rem;
      .listTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.3077rem;
        height: 1.2308rem;
        span {
          flex: 1;
          font-size: 0.3846rem;
          color: #fff;
          font-weight: 700;
        }
        :nth-child(2) {
          text-align: center;
        }
        :nth-child(3) {
          text-align: right;
        }
      }
      ul {
        width: 100%;
        height: 3.8462rem;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 0.1026rem;
        }
        &::-webkit-scrollbar-track {
          border-radius: 0.0513rem;
          background-color: #525252;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0.0513rem;
          box-shadow: inset 0 0 6px #b7b7b7;
          background: #b7b7b7;
        }
        li {
          display: flex;
          height: 1.2308rem;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.3077rem;
          span {
            flex: 1;
            font-size: 0.3333rem;
            color: #b7b7b7;
          }
          :nth-child(2) {
            text-align: center;
          }
          :nth-child(3) {
            text-align: right;
          }
        }
        li:nth-child(2n-1) {
          background: rgba($color: #000000, $alpha: 0.3);
        }
      }
    }
  }
</style>

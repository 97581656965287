<template>
  <div class="popup">
    <div class="title">移除KNT</div>
    <div class="iptBox">
      <input type="number" min="0" v-model="knt" />
    </div>
    <div class="blance">我的质押:{{ kntTotal }}KNT</div>
    <div class="popupBtn">
      <button @click="close">取消</button>
      <button @click="remove">确认</button>
    </div>
    <span class="close" @click="close"></span>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { ref } from 'vue'
  export default {
    name: 'Remove',
    emits: ['closeDialog', 'kntRelieve'],
    props: ['kntTotal'],
    setup(props, context) {
      let close = () => {
        context.emit('closeDialog')
      }
      let knt = ref(null)
      let remove = () => {
        if (props.kntTotal < knt.value) {
          return Toast.fail('您没有这么多质押')
        }
        if (knt.value <= 0) {
          return Toast.fail('移除数量必须大于0')
        }
        if (
          knt.value.toString().split('.')[1] &&
          knt.value.toString().split('.')[1].length > 2
        ) {
          return Toast.fail('移除数量小数不能超过两位')
        }
        context.emit('kntRelieve', knt.value)
      }
      return { close, knt, remove }
    },
  }
</script>

<style lang="scss" scoped>
  .popup {
    position: relative;
    padding: 0.5128rem;
    width: 8.2051rem;
    height: 7.1795rem;
    .title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.4615rem;
      font-weight: 700;
    }
    .iptBox {
      width: 100%;
      margin-top: 0.6154rem;
      input {
        text-align: center;
        width: 100%;
        height: 1.8462rem;
        background-color: #333b51;
        outline: none;
        border: 0.0256rem solid #3c486c;
        border-radius: 0.2051rem;
        font-size: 0.6154rem;
        color: #fff;
      }
    }
    .blance {
      color: #fff;
      opacity: 0.5;
      margin-top: 0.6154rem;
      font-size: 0.3077rem;
    }
    .popupBtn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0.8205rem;
      width: 100%;
      button {
        height: 1.0256rem;
        width: 2.8718rem;
        border-radius: 0.5128rem;
        background-color: #313748;
        color: #fff;
        font-size: 0.4103rem;
      }
      :nth-child(2) {
        background: linear-gradient(to right, #ffd25e, #d28500);
      }
    }
    .close {
      width: 0.4103rem;
      height: 0.4103rem;
      position: absolute;
      right: 0.3077rem;
      top: 0.3077rem;
      background: url('../images/close@2x.png') center no-repeat;
      background-size: 0.4103rem;
    }
  }
</style>

<template>
  <div class="content">
    <div class="knightJourney">
      <div class="title">{{ $t('dapp.knight.KnightJourney') }}</div>
      <div class="knightContent">
        <div class="knt">
          <div class="addKnt">
            <div class="left"><i></i>KNT</div>
            <div class="right">
              <button v-if="!isAuthor" @click="authorize">
                {{ $t('dapp.knight.authorization') }}
              </button>
              <div v-if="isAuthor">
                <span
                  class="remove"
                  data-show="remove"
                  @click="showDialog"
                ></span>
                <span class="add" data-show="add" @click="showDialog"></span>
              </div>
            </div>
          </div>
          <div class="gct">
            <div class="gctTitle">
              <div class="left"><i></i></div>
            </div>
            <div v-if="isAuthor" class="addGct">
              <span class="add" @click="addGct"></span>
            </div>
          </div>
          <div class="inputKnt">
            <div>{{ viewKntProfit }} ART</div>
            <button v-if="!isAuthor" @click="authorize">
              {{ $t('dapp.knight.authorization') }}
            </button>
            <button v-if="isAuthor" @click="harvestKnt">
              {{ $t('dapp.knight.harvest') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="directPush">
      <div class="directTitle">{{ $t('dapp.knight.invitationBenefits') }}</div>
      <div class="directContent">
        <div>{{ viewFeeInvited }} ART</div>
        <button v-if="!isAuthor" @click="authorize">
          {{ $t('dapp.knight.authorization') }}
        </button>
        <button v-if="isAuthor" @click="withDrawFeeInvited">
          {{ $t('dapp.knight.harvest') }}
        </button>
      </div>
    </div>
    <div class="nodeEarnings">
      <div class="nodeEarningsTitle">{{ $t('dapp.knight.nodeReturns') }}</div>
      <div class="nodeEarningsContent">
        <div>{{ nodePofit }} ART</div>
        <button v-if="!isAuthor" @click="authorize">
          {{ $t('dapp.knight.authorization') }}
        </button>
        <button v-if="isAuthor" @click="withDrawNodeProfit">
          {{ $t('dapp.knight.harvest') }}
        </button>
      </div>
    </div>
    <van-dialog v-model:show="show" :showConfirmButton="false" class="popup">
      <Add
        v-show="addOrRemove"
        @closeDialog="closeDialog"
        :balance="balance"
        @kntDeposit="kntDeposit"
      ></Add>
      <Remove
        v-show="!addOrRemove"
        @closeDialog="closeDialog"
        :kntTotal="kntTotal"
        @kntRelieve="kntRelieve"
      ></Remove>
    </van-dialog>
    <van-dialog v-model:show="gctShow" :showConfirmButton="false" class="popup">
      <div class="addGctContent">
        <div class="title">添加GCT</div>
        <div class="iptBox">
          <input type="number" min="0" v-model="gctNum" />
        </div>
        <div class="blance">我的余额:{{ gctBalance }}GCT &nbsp; &nbsp; &nbsp; </div>
        <div class="popupBtn">
          <button @click="closeAddGct">取消</button>
          <button @click="gctDeposit">确认</button>
        </div>
        <span class="close" @click="closeAddGct"></span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { formatAmount } from '@/utils/format.js'
  import Add from './Add'
  import Remove from './Remove'
  import { Dialog, Toast } from 'vant'
  import { ref, reactive, onMounted, toRefs, computed, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import Web3 from 'web3'
  import detectEthereumProvider from '@metamask/detect-provider'
  import pool from '@/web3/pool.json'
  import address from '@/web3/address.json'
  import token from '@/web3/token.json'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'Knight',
    props: ['invitedMemberLength'],
    setup(props) {
      //#region 定义的数据
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const refresh = computed(() => store.state.refreshData)
      watch(
        () => refresh,
        (value) => {
          if (value) {
            initData()
          }
        },
        { deep: true }
      )
      const authFeeLimit = ref(
        '0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      let isAuthor = ref(false)
      let poolAbi = reactive({})
      let gtcAbi = reactive({})
      let kntAbi = reactive({})
      let gctAbiNew = reactive({})
      let bnbAbi = reactive({})
      let web = reactive({})
      let userAddress = window.ethereum.selectedAddress
      let inviteAddress = window.ethereum.selectedAddress
      if (route.query.f) {
        inviteAddress = route.query.f
      }
      let userData = reactive({
        nodePofit: 0, //节点收益
        lockInvitedProfit: 0, //直推收益未结算
        lockTeamProfit: 0, //团队收益未结算
        viewFeeInvited: 0, //手续费直推收益
        balance: 0, //余额
        kntTotal: 0, //knt 质押总额
        gctBalance: 0, //gtc余额
        gstBalance: 0,
        gctTotal: 0, //质押的gtc
        viewKntProfit: 0, //质押knt的收益
      })
      //#endregion
      if (!userAddress) {
        setTimeout(() => {
          if (route.query.f) {
            inviteAddress = route.query.f
          }
          userAddress = window.ethereum.selectedAddress
          initContrac()
        }, 1000)
      } else {
        if (route.query.f) {
          inviteAddress = route.query.f
        }
        initContrac()
      }
      //#region 初始化和获取数据
      async function initContrac() {
        const Provider = await reactive(detectEthereumProvider())
        const web3 = reactive(new Web3(Provider))
        web = web3
        poolAbi = new web3.eth.Contract(pool, address.pool)
        gtcAbi = new web3.eth.Contract(token, address.gctToken)
        kntAbi = new web3.eth.Contract(token, address.kntToken)
        gctAbiNew = new web3.eth.Contract(token, address.gctTokenNew)
        bnbAbi = new web3.eth.Contract(token, address.bnbTest)
        isAuthorization()
        //获取数据的函数
        initData()
      }
      async function initData() {
        //获取精度
        let precision = await kntAbi.methods.decimals().call()
        // 获取节点收益
        let nodePending = await poolAbi.methods.nodePending(userAddress).call()
        userData.nodePofit = formatAmount(
          Number(nodePending),
          Number(precision)
        )
        //获取手续费收益
        let viewFeeInvited = await poolAbi.methods
          .viewFeeInvited()
          .call({ from: userAddress })
        userData.viewFeeInvited = formatAmount(
          Number(viewFeeInvited),
          Number(precision)
        )
        // 获取投资总额
        let getUserInfo = await poolAbi.methods
          .getUserInfo()
          .call({ from: userAddress })
        //gtc投资总额度
        userData.kntTotal = Number(
          formatAmount(Number(getUserInfo.kntTotal), Number(precision))
        )
        // knt质押额度
        userData.gctTotal = Number(
          formatAmount(Number(getUserInfo.gctTotal), Number(precision))
        )

        //获取余额
        let balance = await kntAbi.methods.balanceOf(userAddress).call()
        userData.balance = Number(
          formatAmount(Number(balance), Number(precision))
        )
        //获取gct余额
        let gctBalance = await gtcAbi.methods.balanceOf(userAddress).call()
        userData.gctBalance = Number(
          formatAmount(Number(gctBalance), Number(precision))
        )
        let gstBalance = await gctAbiNew.methods.balanceOf(userAddress).call()
        userData.gstBalance = Number(
            formatAmount(Number(gstBalance), Number(precision))
        )

        // 获取knt收益
        let viewKntProfit = await poolAbi.methods
          .viewKntProfit()
          .call({ from: userAddress })
        userData.viewKntProfit = Number(
          formatAmount(Number(viewKntProfit), Number(precision))
        )
      }
      //#endregion

      //#region knt&gct质押
      //质押knt
      async function kntDeposit(value) {
        let precision = await kntAbi.methods.decimals().call()
        value = formatAmount(Number(value), -Number(precision))
        Toast.loading({
          message: t('nft.issuance.loading'),
          forbidClick: true,
          duration: 0,
        })
        try {
          await poolAbi.methods
            .kntDeposit(inviteAddress, value)
            .send({ from: userAddress })
          Toast.success(t('toast.win'))
          closeDialog()
          store.commit('REFRESH')
          store.commit('REFRESH')
        } catch (error) {
          console.log(error)
          Toast.fail(t('toast.fail'))
        }
      }
      //解除KNT质押
      async function kntRelieve(values) {
        let fee = await poolAbi.methods.getkntRelieveFee().call()
        let precision = await kntAbi.methods.decimals().call()
        values = formatAmount(Number(values), -Number(precision))
        Toast.loading({
          message: t('nft.issuance.loading'),
          forbidClick: true,
          duration: 0,
        })
        try {
          await poolAbi.methods
            .kntRelieve(values)
            .send({ from: userAddress, value: fee })
          Toast.success(t('toast.win'))
          store.commit('REFRESH')
          store.commit('REFRESH')
          closeDialog()
        } catch (error) {
          Toast.fail(t('toast.fail'))
        }
      }
      //质押gct
      async function gctDeposit() {
        if (gctNum.value.toString().split('.')[1] || gctNum.value < 1) {
          return Toast.fail(t('toast.positiveInteger'))
        }
        if (userData.gctBalance|| userData.gstBalance >= gctNum.value) {
          let precision = await gtcAbi.methods.decimals().call()
          let value = formatAmount(Number(gctNum.value), -Number(precision))
          Toast.loading({
            message: t('nft.issuance.loading'),
            forbidClick: true,
            duration: 0,
          })
          try {
            await poolAbi.methods
              .gctDeposit(inviteAddress, value)
              .send({ from: userAddress })
            Toast.success(t('toast.win'))
            store.commit('REFRESH')
            store.commit('REFRESH')
            gctShow.value = false
          } catch (error) {
            Toast.fail(t('toast.fail'))
          }
        } else {
          Toast.fail(t('toast.GCTLess'))
        }
      }
      //#endregion

      //#region 收获的函数
      //收获节点收益
      async function withDrawNodeProfit() {
        let precision = await kntAbi.methods.decimals().call()
        let fee = await poolAbi.methods.getFee().call()
        let balance = await web.eth.getBalance(userAddress)
        let bnbBlance = Number(formatAmount(Number(balance), Number(precision)))
        let feeNum = Number(formatAmount(Number(fee), Number(precision)))
        if (bnbBlance < feeNum) {
          return Toast.fail(t('toast.moneyCover'))
        }
        if (userData.nodePofit == 0) {
          return Toast.fail(t('toast.yieldZero'))
        }
        Toast.loading({
          message: t('nft.issuance.loading'),
          forbidClick: true,
          duration: 0,
        })
        try {
          await poolAbi.methods
            .withDrawNodeProfit()
            .send({ from: userAddress, value: fee })
          Toast.success(t('toast.win'))
          store.commit('REFRESH')
          store.commit('REFRESH')
        } catch (error) {
          Toast.fail(t('toast.fail'))
        }
      }
      //收获直推手续费收益
      async function withDrawFeeInvited() {
        let precision = await kntAbi.methods.decimals().call()
        let fee = await poolAbi.methods.getFee().call()
        let balance = await web.eth.getBalance(userAddress)
        let bnbBlance = Number(formatAmount(Number(balance), Number(precision)))
        let feeNum = Number(formatAmount(Number(fee), Number(precision)))
        if (bnbBlance < feeNum) {
          return Toast.fail(t('toast.moneyCover'))
        }
        if (props.invitedMemberLength == 0) {
          return Toast.fail(t('toast.notInvitedFriends'))
        }
        if (userData.feeInvitedProfit == 0) {
          return Toast.fail(t('toast.yieldZero'))
        }
        Toast.loading({
          message: t('nft.issuance.loading'),
          forbidClick: true,
          duration: 0,
        })
        try {
          await poolAbi.methods
            .withDrawFeeInvited()
            .send({ from: userAddress, value: fee })
          Toast.success(t('toast.win'))
          store.commit('REFRESH')
          store.commit('REFRESH')
        } catch (error) {
          Toast.fail(t('toast.fail'))
        }
      }
      //收获knt的收益
      async function harvestKnt() {
        let precision = await kntAbi.methods.decimals().call()
        let fee = await poolAbi.methods.getFee().call()
        let balance = await web.eth.getBalance(userAddress)
        let bnbBlance = Number(formatAmount(Number(balance), Number(precision)))
        let feeNum = Number(formatAmount(Number(fee), Number(precision)))
        if (bnbBlance < feeNum) {
          return Toast.fail(t('toast.moneyCover'))
        }
        if (userData.viewKntProfit == 0) {
          return Toast.fail(t('toast.yieldZero'))
        }
        Toast.loading({
          message: t('nft.issuance.loading'),
          forbidClick: true,
          duration: 0,
        })
        try {
          await poolAbi.methods
            .drawProfit()
            .send({ from: userAddress, value: fee })
          Toast.success(t('toast.win'))
          store.commit('REFRESH')
          store.commit('REFRESH')
        } catch (error) {
          Toast.fail(t('toast.fail'))
        }
      }
      //#endregion

      //#region 授权
      //是否授权
      async function isAuthorization() {
        const result1 = await gtcAbi.methods
          .allowance(userAddress, address.pool)
          .call()
        const result2 = await kntAbi.methods
          .allowance(userAddress, address.pool)
          .call()
        const result3 = await gctAbiNew.methods
            .allowance(userAddress, address.pool)
            .call()
        if (result1 != 0 && result2 != 0 && result3 != 0) {
          isAuthor.value = true
        } else {
          isAuthor.value = false
        }
      }
      // 调取授权函数
      async function authorize() {
        Toast.loading({
          message: t('toast.authorize'),
          forbidClick: true,
          duration: 0,
        })
        try {
          await gtcAbi.methods
            .approve(address.pool, authFeeLimit.value)
            .send({ from: userAddress })
          auther()
        } catch (error) {
          Toast.fail(t('toast.fail'))
          isAuthor.value = false
        }
      }
      async function auther() {
        try {
          await kntAbi.methods
            .approve(address.pool, authFeeLimit.value)
            .send({ from: userAddress })
          authorizeNewGct()
        } catch (error) {
          Toast.fail(t('toast.fail'))
          isAuthor.value = false
        }
      }
      async function authorizeNewGct() {
        try {
          await gctAbiNew.methods
              .approve(address.pool, authFeeLimit.value)
              .send({ from: userAddress })
          Toast.success(t('toast.win'))
          isAuthor.value = true
        } catch (error) {
          Toast.fail(t('toast.fail'))
          isAuthor.value = false
        }
      }
      //#endregion

      //#region 骑士征程弹出框模块
      //弹出框
      let addOrRemove = ref(true)
      const show = ref(false)
      let showDialog = (e) => {
        if (e.target.dataset.show == 'remove') {
          addOrRemove.value = false
        } else {
          addOrRemove.value = true
        }
        show.value = true
      }
      function closeDialog() {
        show.value = false
      }
      let gctShow = ref(false)
      let gctNum = ref(null)
      //添加gct
      let addGct = () => {
        gctShow.value = true
      }
      //关闭gtc
      let closeAddGct = () => {
        gctShow.value = false
        gctNum.value = null
      }

      //#endregion
      onMounted(() => {})

      return {
        show,
        showDialog,
        addOrRemove,
        closeDialog,
        isAuthor,
        authorize,
        ...toRefs(userData),
        kntDeposit,
        kntRelieve,
        gctDeposit,
        withDrawNodeProfit,
        withDrawFeeInvited,
        harvestKnt,
        addGct,
        gctShow,
        closeAddGct,
        gctNum,
      }
    },
    components: { [Dialog.Component.name]: Dialog.Component, Add, Remove },
  }
</script>

<style lang="scss" scsoped>
  .content {
    width: 100%;
    .knightJourney {
      margin-top: 1.0256rem;
      width: 100%;
      .title {
        font-size: 0.4615rem;
        background-image: -webkit-gradient(
          linear,
          left center,
          right center,
          from(#ffd25e),
          to(#d28500)
        ); /* 背景色渐变 */
        background-clip: text; /* 规定背景的划分区域 */
        -webkit-text-fill-color: transparent; /* 防止字体颜色覆盖 */
        font-weight: 700;
        margin-bottom: 0.2051rem;
      }
      .knightContent {
        display: flex;
        padding: 0.5128rem;
        height: 7.3846rem;
        background: rgba($color: #fff, $alpha: 0.1);
        border-radius: 0.3077rem;
        width: 100%;
        .knt {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .addKnt {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.8205rem;
            width: 100%;
            .left {
              display: flex;
              align-items: center;
              font-size: 0.4103rem;
              color: #fff;
              i {
                margin-right: 0.2051rem;
                width: 0.8205rem;
                height: 0.8205rem;
                background: url('./images/KNT.png') center no-repeat;
                background-size: 0.8205rem;
              }
            }
            .right {
              button {
                height: 0.8205rem;
                padding: 0 0.5128rem;
                background: linear-gradient(to right, #ffd25e, #d28500);
                color: #fff;
                border-radius: 0.4103rem;
              }
              div {
                display: flex;
                justify-content: space-between;
                width: 2.0513rem;
                height: 0.8205rem;
                span {
                  width: 0.8205rem;
                  height: 0.8205rem;
                }
                :nth-child(1) {
                  background: url('./images/remove.png') center no-repeat;
                  background-size: 0.8205rem;
                }
                :nth-child(2) {
                  background: url('./images/add@2x.png') center no-repeat;
                  background-size: 0.8205rem;
                }
              }
              button {
                width: 2.4615rem;
              }
            }
          }
          .inputKnt {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-left: 0.4103rem;
            padding-right: 0.1026rem;
            height: 1.2308rem;
            background-color: #2b3341;
            border: 1px solid #505050;
            border-radius: 0.2051rem;
            color: #fff;
            margin-top: 0.3077rem;
            div {
              font-size: 0.4103rem;
            }
            button {
              height: 1.0256rem;
              padding: 0 0.5128rem;
              background: linear-gradient(to right, #ffd25e, #d28500);
              border-radius: 0.2051rem;
              font-size: 0.359rem;
            }
          }
        }
        .gct {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .gctTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.8205rem;
            .left {
              display: flex;
              align-items: center;
              font-size: 0.4103rem;
              color: #fff;
              i {
                margin-right: 0.2051rem;
                width: 2.0513rem;
                height: 0.8205rem;
                background: url('./images/gct.png') no-repeat;
                background-size: 2.0513rem 0.8205rem;
              }
            }
          }
          .addGct {
            display: flex;
            width: 2.0513rem;
            justify-content: flex-end;
            span {
              width: 0.8205rem;
              height: 0.8205rem;
              background: url('./images/add@2x.png') center no-repeat;
              background-size: 0.8205rem;
            }
          }
        }
      }
    }
    .directPush {
      margin-top: 1.0256rem;
      width: 100%;
      .directTitle {
        font-size: 0.4615rem;
        background-image: -webkit-gradient(
          linear,
          left center,
          right center,
          from(#ffd25e),
          to(#d28500)
        ); /* 背景色渐变 */
        background-clip: text; /* 规定背景的划分区域 */
        -webkit-text-fill-color: transparent; /* 防止字体颜色覆盖 */
        font-weight: 700;
        margin-bottom: 0.2051rem;
      }
      .directContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 0.4103rem;
        padding-right: 0.1026rem;
        height: 1.2308rem;
        background-color: #2b3341;
        border: 1px solid #505050;
        border-radius: 0.2051rem;
        color: #fff;
        margin-top: 0.3077rem;
        div {
          font-size: 0.4103rem;
        }
        button {
          height: 1.0256rem;
          padding: 0 0.5128rem;
          background: linear-gradient(to right, #ffd25e, #d28500);
          border-radius: 0.2051rem;
          font-size: 0.359rem;
        }
      }
    }
    .nodeEarnings {
      margin-top: 1.0256rem;
      width: 100%;
      .nodeEarningsTitle {
        font-size: 0.4615rem;
        background-image: -webkit-gradient(
          linear,
          left center,
          right center,
          from(#ffd25e),
          to(#d28500)
        ); /* 背景色渐变 */
        background-clip: text; /* 规定背景的划分区域 */
        -webkit-text-fill-color: transparent; /* 防止字体颜色覆盖 */
        font-weight: 700;
        margin-bottom: 0.2051rem;
      }
      .nodeEarningsContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 0.4103rem;
        padding-right: 0.1026rem;
        height: 1.2308rem;
        background-color: #2b3341;
        border: 1px solid #505050;
        border-radius: 0.2051rem;
        color: #fff;
        margin-top: 0.3077rem;
        div {
          font-size: 0.4103rem;
        }
        button {
          height: 1.0256rem;
          padding: 0 0.5128rem;
          background: linear-gradient(to right, #ffd25e, #d28500);
          border-radius: 0.2051rem;
          font-size: 0.359rem;
        }
      }
    }
  }
  .popup {
    overflow: hidden;
    width: 8.2051rem;
    border-radius: 0.3077rem;
    background: linear-gradient(to top, #202945, #161e34);
  }
  .addGctContent {
    position: relative;
    padding: 0.5128rem;
    width: 8.2051rem;
    height: 7.1795rem;
    .title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.4615rem;
      font-weight: 700;
    }
    .iptBox {
      width: 100%;
      margin-top: 0.6154rem;
      input {
        text-align: center;
        width: 100%;
        height: 1.8462rem;
        background-color: #333b51;
        outline: none;
        border: 0.0256rem solid #3c486c;
        border-radius: 0.2051rem;
        font-size: 0.6154rem;
        color: #fff;
      }
    }
    .blance {
      color: #fff;
      opacity: 0.5;
      margin-top: 0.6154rem;
      font-size: 0.3077rem;
    }
    .popupBtn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0.8205rem;
      width: 100%;
      button {
        height: 1.0256rem;
        width: 2.8718rem;
        border-radius: 0.5128rem;
        background-color: #313748;
        color: #fff;
        font-size: 0.4103rem;
      }
      :nth-child(2) {
        background: linear-gradient(to right, #ffd25e, #d28500);
      }
    }
    .close {
      width: 0.4103rem;
      height: 0.4103rem;
      position: absolute;
      right: 0.3077rem;
      top: 0.3077rem;
      background: url('./images/close@2x.png') center no-repeat;
      background-size: 0.4103rem;
    }
  }
</style>

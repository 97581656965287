<template>
  <div class="newApp">
    <!-- 产量信息 -->
    <UserYield></UserYield>
    <Knight :invitedMemberLength="invitedMemberLength"></Knight>
    <!-- 邀请人 -->
    <Invitation></Invitation>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import UserYield from './UserYield'
  import Knight from './Knight'
  import Invitation from './Invitation'
  import { onMounted, reactive, toRefs, ref, onBeforeUnmount } from 'vue'
  import Web3 from 'web3'
  import detectEthereumProvider from '@metamask/detect-provider'
  import pool from '@/web3/pool.json'
  import address from '@/web3/address.json'
  import token from '@/web3/token.json'
  import { formatAmount } from '@/utils/format.js'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'NewApp',
    setup(props) {
      let store = useStore()
      let poolAbi = reactive({})
      let gtcAbi = reactive({})
      let kntAbi = reactive({})
      const { t } = useI18n()
      //#region 链接钱包
      function init() {
        if (
          window.ethereum === undefined ||
          window.ethereum.isMetaMask === false
        ) {
        } else {
          window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((res) => {
              userAddress = window.ethereum.selectedAddress
            })
            .catch((err) => {
              Toast.fail(t('toast.metaMask'))
            })
        }
      }
      //#endregion
      //初始化合约
      let invitedMemberLength = ref(null)
      // 获取用户地址
      let userAddress = window.ethereum.selectedAddress
      if (!window.ethereum.selectedAddress) {
        setTimeout(() => {
          userAddress = window.ethereum.selectedAddress
          initContrac()
        }, 1000)
      } else {
        initContrac()
      }
      async function initContrac() {
        const Provider = await reactive(detectEthereumProvider())
        // Provider = new Provider(httpurl, chainid);
        const web3 = reactive(new Web3(Provider))
        poolAbi = new web3.eth.Contract(pool, address.pool)
        gtcAbi = new web3.eth.Contract(token, address.gctToken)
        kntAbi = new web3.eth.Contract(token, address.kntToken)
        let getInvitedMembers = await poolAbi.methods
          .getInvitedMembers()
          .call({ from: window.ethereum.selectedAddress })
        invitedMemberLength.value = getInvitedMembers.length
      }
      let timer = null
      onMounted(() => {
        init()
        timer = setInterval(() => {
          store.commit('REFRESH')
          store.commit('REFRESH')
        }, 5000)
      })
      onBeforeUnmount(() => {
        clearInterval(timer)
      })
      return { invitedMemberLength }
    },
    components: { UserYield, Knight, Invitation },
  }
</script>

<style lang="scss" scoped>
  .newApp {
    width: 100%;
    padding: 1.5385rem 0.3846rem 1.5rem;
    background: url('./images/staff_1024@3x.png') center 1.5385rem no-repeat,
      url('./Invitation/images/100069640@3x.png') bottom no-repeat;
    background-size: 100%;
  }
</style>
